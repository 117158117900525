import React, { createRef, useState, useEffect } from 'react'
import {
  Tooltip as MaterialTooltip,
  TooltipProps as MaterialTooltipProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import color from '../../../styles/color'

export type TooltipProps = {
  showOnlyEllipsis?: boolean // 「...」 で文字が省略されたときだけツールチップを表示するかどうか
} & React.StyledProps &
  MaterialTooltipProps

const TOOLTIP_MARGIN_LEFT = -4

const useStyles = makeStyles({
  tooltip: {
    maxWidth: 240, // TODO: 後に修正
    backgroundColor: color.black,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '14px',
  },
  placementTop: {
    // NOTE: Material-UI tooltip の default {margin-bottom: 14px} を top に向くように
    margin: `28px 0 0 ${TOOLTIP_MARGIN_LEFT}px`,
  },

  // NOTE: 上部にツールチップを表示する余裕がない場合、自動で下部に移動してしまうため、その時用のスタイル
  placementBottom: {
    margin: `0 0 0 ${TOOLTIP_MARGIN_LEFT}px`,
  },
})

const Tooltip: React.StyledFC<TooltipProps> = ({
  className,
  title,
  disableHoverListener,
  enterDelay,
  children,
  showOnlyEllipsis,
  placement = 'top-start',
}: TooltipProps) => {
  const classes = useStyles()

  const ref = createRef<HTMLDivElement>()

  const [isShowEllipsis, setIsShowEllipsis] = useState(false)

  useEffect(() => {
    if (ref && ref.current && ref.current.firstElementChild) {
      const { scrollWidth, clientWidth } = ref.current.firstElementChild
      setIsShowEllipsis(scrollWidth > clientWidth)
    }
  }, [ref, title, children])

  return (
    <MaterialTooltip
      className={className}
      classes={{
        tooltip: classes.tooltip,
        tooltipPlacementTop: classes.placementTop,
        tooltipPlacementBottom: classes.placementBottom,
      }}
      title={title}
      disableHoverListener={
        disableHoverListener || (showOnlyEllipsis && !isShowEllipsis)
      }
      enterDelay={enterDelay}
      placement={placement}
    >
      <div ref={ref}>{children}</div>
    </MaterialTooltip>
  )
}

export default Tooltip
