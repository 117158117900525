import React, {
  useState,
  UIEvent,
  cloneElement,
  ReactElement,
  useCallback,
} from 'react'
import { Box, Typography } from '@mui/material'
import style from './styles.module.scss'
import {
  BREADCRUMB_HEIGHT,
  TABS_WITH_LINE_HEIGHT,
} from '../../../styles/constants'
import Breadcrumbs, { BreadcrumbsProps } from '../../molecules/Breadcrumbs'
import QRCodeModal, { Section } from '../../molecules/QRCodeModal'
import { TabsProps } from '../../molecules/Tabs'
import TabsWithLine from '../../molecules/TabsWithLine'
import StoreTenantHeader, {
  StoreTenantHeaderProps,
} from '../../organisms/StoreTenantHeader'
import Loading from '../Loading'
import { RoleEnum } from '../../../slices/services/api'
import { DslUpdateRoles, isOneOfRoles } from '../../../domain/role'

export type TenantDetailProps = {
  breadcrumbPaths: BreadcrumbsProps['paths']
  tabOptions: TabsProps['options']
  tabIndex: number
  tabContents: ReactElement[]
  isLoading?: boolean
  modalTitle: string
  qrSrc: string
  qrSize: number
  modalOpen: boolean
  downloadButtonText: string
  disableBackdropClick?: boolean
  qrPdfElementId: string
  sections: Section[]
  role?: RoleEnum
  onSelectTab: (index: number) => void
  onClickDownload: (elementId: string) => void
  onClickClose: () => void
} & StoreTenantHeaderProps

export type Status = 'ready' | 'not_ready' | 'closed'

const TenantDetail: React.StyledFC<TenantDetailProps> = ({
  tenantName,
  tenantCode,
  status,
  breadcrumbPaths,
  tabOptions,
  tabIndex,
  tabContents,
  isLoading = false,
  showButtonText,
  modalOpen,
  disableBackdropClick,
  modalTitle,
  downloadButtonText,
  qrPdfElementId,
  qrSrc,
  qrSize,
  sections,
  role,
  onSelectTab,
  onClickShowModal,
  onClickDownload,
  onClickClose,
}: TenantDetailProps) => {
  const [showsUnderline, setShowsUnderline] = useState(false)

  const handleClick = (index: number) => {
    onSelectTab(index)
  }

  const handleScroll = (e: UIEvent) => {
    // タイトルのスペース(74px)
    setShowsUnderline(e.currentTarget.scrollTop > 74 + BREADCRUMB_HEIGHT)
  }

  const showSwitchByRole = useCallback((): boolean => {
    return isOneOfRoles(DslUpdateRoles, role)
  }, [role])

  return (
    <Box className={style.root} onScroll={handleScroll}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Breadcrumbs className={style.breadcrumb} paths={breadcrumbPaths} />
          <StoreTenantHeader
            className={style.header}
            tenantName={tenantName}
            tenantCode={tenantCode}
            status={status}
            showButtonText={showButtonText}
            onClickShowModal={onClickShowModal}
          />
          {showSwitchByRole() && (
            <>
              <TabsWithLine
                className={style.tab}
                options={tabOptions}
                selectedIndex={tabIndex}
                onClick={(index) => handleClick(index)}
              />
              <Box className={style.tabContents} data-testid="tabContents">
                {cloneElement(tabContents[tabIndex], {
                  showsUnderline,
                  stickyTop: TABS_WITH_LINE_HEIGHT + BREADCRUMB_HEIGHT,
                })}
              </Box>
            </>
          )}
          {!showSwitchByRole() && (
            <Box className={style.message}>
              <Typography variant="h2">
                テナント認証用QRコードの発行について
              </Typography>
              <Typography variant="body1">
                右上の「QRコード表示」ボタンを押下すると
              </Typography>
              <Typography variant="body1">
                テナント認証用QRコードの表示・ダウンロードを行うことができます。
              </Typography>
            </Box>
          )}
        </>
      )}
      <QRCodeModal
        className={style.modal}
        open={modalOpen}
        title={modalTitle}
        disableBackdropClick={disableBackdropClick}
        buttonText={downloadButtonText}
        sections={sections}
        id={qrPdfElementId}
        src={qrSrc}
        size={qrSize}
        onClickDownload={() => onClickDownload(qrPdfElementId)}
        onClickClose={onClickClose}
      />
    </Box>
  )
}

export default TenantDetail
