/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import { FormProvider, useFormContext } from 'react-hook-form'

import { RoleEnum, Store, Tenant, TenantDetail } from 'src/slices/services/api'
import CenteringLoading from 'src/components/molecules/CenteringLoading'
import Modal from 'src/components/molecules/Modal/index'
import { Column } from 'src/components/tables/AppTableHead'
import { ZoomableReceiptImageProps } from 'src/components/molecules/ZoomableReceiptImage'
import Icon from 'src/components/atoms/Icon'
import { ReportMessage } from 'src/components/molecules/ReportMessages'
import { Option } from 'src/components/molecules/SelectWithGroupOption'
import NextImageLoader from 'src/components/molecules/NextImageLoader'
import TenantSalesReportRegister from '../../components/TenantSalesReportRegister'
import TenantSalesReportDateOptions from '../../components/DateOptions'
import ProgressConfirmBar from '../../components/ProgressConfirmBar'
import TenantSalesReportInfo from '../../components/Info'
import TenantSalesReportMessages from '../../components/Messages'
import TenantName from '../../components/TenantName'
import SalesDate from '../../components/SalesDate'
import styles from './styles.module.scss'
import {
  FormNameEnum,
  FormTypesTenantSalesReport,
  Register,
  RegisterWithSum,
} from '../../type'
import { calculateSumCell, hasExceededSumCell } from './calculateSumCell'

export type TenantSalesReportProps = {
  isLoading?: boolean
  isEnableToFinalizeAndFilterByMisMatchAndSendMessageByRoles: boolean
  orgCode: string
  store?: Store
  tenant?: Tenant
  tenantPath: string
  date: string
  selectedDate?: string
  registers: Register[]
  columns: Column[]
  showModal: boolean
  tenantDetail?: TenantDetail
  manageMessage?: string
  disabledConfirmButton?: boolean
  progress: number
  totalCount: number
  currentCount: number
  selectedResentImage?: ZoomableReceiptImageProps
  reportMessages: ReportMessage[]
  role?: RoleEnum
  selectOptions: Option[]
  selectPlaceholder: string
  selectDefaultValue?: string
  selectedTenantCodes?: string[]
  onClickConfirm: () => void
  onClickBack: () => void
  onClickClose: () => void
  onClickSkip: () => void
  onClickModalConfirm: () => void
  onClickModalCancel: () => void
  onClickValue: (
    value: string,
    registerIndex: number,
    rowIndex: number,
    childIndex?: number
  ) => void
}

const TenantSalesReportTemplate: React.StyledFC<TenantSalesReportProps> = ({
  isLoading,
  isEnableToFinalizeAndFilterByMisMatchAndSendMessageByRoles,
  orgCode,
  store,
  tenant,
  tenantPath,
  date,
  registers,
  columns,
  tenantDetail,
  manageMessage,
  showModal,
  disabledConfirmButton,
  progress,
  totalCount,
  currentCount,
  selectedResentImage,
  reportMessages,
  role,
  selectOptions,
  selectPlaceholder,
  selectDefaultValue,
  selectedDate,
  selectedTenantCodes,
  onClickConfirm,
  onClickBack,
  onClickClose,
  onClickSkip,
  onClickModalConfirm,
  onClickModalCancel,
  onClickValue,
}: TenantSalesReportProps) => {
  const formMethods = useFormContext<FormTypesTenantSalesReport>()
  const rootRef = useRef<HTMLDivElement>(null)
  const [isCurrentImageLoaded, setIsCurrentImageLoaded] =
    useState<boolean>(false)
  const [isNextImageLoaded, setIsNextImageLoaded] = useState<boolean>(false)
  const [nextTenantCode, setNextTenantCode] = useState<string>()
  const [isNextTenantCodeUpdated, setIsNextTenantCodeUpdated] =
    useState<boolean>(false)
  // NOTE: 画像の事前ロードを行うNextImageLoaderを呼びだすための条件 tenantの画像のロードが完了していて一つ次のtenantの画像は未ロードの場合、trueになる
  const isReadyToLoadNextImage =
    isCurrentImageLoaded && !isNextImageLoaded && isNextTenantCodeUpdated
  // NOTE: スキップを押した時にテーブルの状態を新しいテナントのものに移行するためのフラグ
  // スキップが押された時に別テナントに遷移中(=true)になり、新しいテナントの情報が取得されuseEffectが走るタイミングでfalse(遷移完了)になる
  const [underTransition, setUnderTransition] = useState<boolean>(true)
  const scrollTop = () => {
    if (rootRef.current?.scroll) {
      rootRef.current.scroll(0, 0)
    }
  }

  useEffect(() => {
    // NOTE: 一つ次のtenantSalesReportを取得
    if (currentCount && selectedTenantCodes) {
      const newNextTenantCode = selectedTenantCodes[currentCount]
      setNextTenantCode(newNextTenantCode)
      setIsNextTenantCodeUpdated(true)
    }
  }, [currentCount, selectedTenantCodes])

  const inputCellWatcher = formMethods.watch(FormNameEnum.registers)

  const [registerWithSums, setRegisterWithSums] = useState<RegisterWithSum[]>(
    calculateSumCell(registers, formMethods.getValues())
  )

  useEffect(() => {
    setUnderTransition(false)
    const calculatedValue = calculateSumCell(registers, formMethods.getValues())
    setRegisterWithSums(calculatedValue)
  }, [inputCellWatcher, registers, formMethods, setRegisterWithSums])

  const handleClickFinalize = () => {
    scrollTop()
    onClickConfirm()
    setIsCurrentImageLoaded(false)
    setIsNextImageLoaded(false)
    setIsNextTenantCodeUpdated(false)
  }
  const handleClickBack = () => {
    scrollTop()
    onClickBack()
    setIsNextImageLoaded(true)
    setIsNextTenantCodeUpdated(true)
  }

  const handleClickSkip = () => {
    setUnderTransition(true)
    scrollTop()
    onClickSkip()
    setIsCurrentImageLoaded(false)
    setIsNextImageLoaded(false)
    setIsNextTenantCodeUpdated(false)
  }

  const handleCurrentImageLoaded = () => {
    setIsCurrentImageLoaded(true)
  }

  const handleNextImageLoaded = () => {
    setIsNextImageLoaded(true)
    setIsNextTenantCodeUpdated(false)
  }

  return (
    <FormProvider {...formMethods}>
      <form>
        <Box className={styles.root} ref={rootRef}>
          <Box className={styles.header}>
            <Box className={styles.headerMainColumn}>
              <TenantSalesReportDateOptions
                className={styles.headerMainLeft}
                selectOptions={selectOptions}
                selectPlaceholder={selectPlaceholder}
                defaultValue={selectDefaultValue}
              />
              <TenantName className={styles.headerMainRight} tenant={tenant} />
            </Box>
            <Box className={styles.headerRightSideBar}>
              <SalesDate date={date} />
              <Box className={styles.closeButtonWrapper}>
                <IconButton
                  className={styles.closeButton}
                  onClick={onClickClose}
                  size="large"
                >
                  <Icon icon="close" />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box className={styles.body}>
            <Box className={styles.bodyMainColumn}>
              {/* NOTE: 別テナントにスキップした時に一つ前のchildRowのデータが引き継がれてしまう */}
              {/* スキップでテナントが変わるたびにHTMLからテーブルを消すことでテーブルの状態をリセットする */}
              {isLoading || underTransition ? (
                <CenteringLoading />
              ) : (
                <>
                  {registerWithSums.map((register, registerIndex) => (
                    <TenantSalesReportRegister
                      className={styles.mainContentList}
                      key={register.key}
                      columns={columns}
                      rows={register.rows}
                      role={role}
                      concatOriginal={
                        selectedResentImage?.concatOriginal ||
                        register.concatOriginal
                      }
                      separatedOriginal={
                        selectedResentImage?.separatedOriginal ||
                        register.separatedOriginal
                      }
                      registerIndex={registerIndex}
                      resized={selectedResentImage?.resized || register.resized}
                      onClickValue={(value, rowIndex, childIndex) =>
                        onClickValue(value, registerIndex, rowIndex, childIndex)
                      }
                      isFirst={registerIndex === 0}
                      onLoaded={handleCurrentImageLoaded}
                    />
                  ))}
                </>
              )}
            </Box>
            <Box className={styles.bodyRightSideBar}>
              <Box className={styles.stickyBar}>
                <TenantSalesReportInfo
                  tenant={tenant}
                  tenantDetail={tenantDetail}
                  store={store}
                  tenantPath={tenantPath}
                />
                <TenantSalesReportMessages
                  reportMessages={reportMessages}
                  tenantMessageBody={tenantDetail?.note || ''}
                  manageMessage={manageMessage}
                  tenantPath={tenantPath}
                  isEnableToFinalizeAndFilterByMisMatchAndSendMessageByRoles={
                    isEnableToFinalizeAndFilterByMisMatchAndSendMessageByRoles
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <ProgressConfirmBar
              className={styles.footerBar}
              progress={progress}
              totalCount={totalCount}
              hasExceededSumCell={hasExceededSumCell(registerWithSums)}
              currentCount={currentCount}
              disabledConfirmButton={disabledConfirmButton}
              isEnableToFinalizeAndFilterByMisMatchAndSendMessageByRoles={
                isEnableToFinalizeAndFilterByMisMatchAndSendMessageByRoles
              }
              onClickSkip={handleClickSkip}
              onClickConfirm={handleClickFinalize}
              onClickBack={handleClickBack}
            />
          </Box>
        </Box>
        <Modal
          open={showModal}
          title={`今一覧に戻ると、テナントの選択状態がリセットされます。\nよろしいですか？`}
          agreeButtonTitle="一覧に戻る"
          disagreeButtonTitle="キャンセル"
          onClickAgree={onClickModalConfirm}
          onClickDisagree={onClickModalCancel}
          onClose={onClickModalCancel}
        />
        {/* NOTE: tenantの画像のロードが完了していて一つ次のtenantの画像は未ロードの場合、isReadyToLoadNextImageがtrueになり、NextImageLoaderが呼び出されて事前ロードが実行される */}
        {isReadyToLoadNextImage && selectedDate && nextTenantCode && store && (
          <NextImageLoader
            orgCode={orgCode}
            storeCode={store?.code}
            selectedDate={selectedDate}
            nextTenantCode={nextTenantCode}
            onLoadComplete={handleNextImageLoaded}
          />
        )}
      </form>
    </FormProvider>
  )
}

TenantSalesReportTemplate.displayName = 'TenantSalesReportTemplate'
export default TenantSalesReportTemplate
