import dayjs from 'dayjs'
import {
  ConvertedAggregatedResult,
  convertToConsistencyEnum,
} from 'src/domain/consistency'
import { ConsistencyMismatch } from '../../../../AppTypes'
import CalculationDSL from '../../../../slices/calculation/calculation_dsl'
import { parseCalculationDSLsToText } from '../../../../slices/calculation/parser'
import {
  ReportStatusEnum,
  SalesReportCalculationResult,
  SalesReportOCRFormatResult,
  SalesReportWithTenant,
} from '../../../../slices/services/api'
import {
  Row,
  Cell,
  ChildRow,
  SalesReportType,
  DefaultFilterValues,
} from '../../type'

interface SalesReportsPresenter {
  convertResponseToTableData: (
    salesReportWithTenants: SalesReportWithTenant[],
    onlyInputMismatch?: boolean
  ) => SalesReportType[][]
  convertFilterValue: (value: string) => ConsistencyMismatch | undefined
  getFilterValuesFromQuery: (
    startDate: string,
    endDate: string,
    reportStatus: ReportStatusEnum[],
    aggregatedResult: string[],
    filterForMismatch: boolean,
    tenantCodeOrName?: string
  ) => DefaultFilterValues
}

const convertValue = (value: number | null): string => {
  return value !== null ? value.toLocaleString() : '-'
}

const convertInputConsistencyResult = (result?: boolean | null): string => {
  if (result === null || result === undefined) return '-'

  return result ? 'OK' : 'NG'
}

const mapResultsToDSLs = (
  results: SalesReportCalculationResult[]
): CalculationDSL[] => {
  return results.map((result) => {
    const calculationItem = {
      id: result.calculationItem.id,
      name: result.calculationItem.name,
    }
    const readItems = result.ocrFormatResults.map((formatResult) => {
      return {
        id: formatResult.ocrFormat.id,
        name: formatResult.ocrFormat.readItem,
      }
    })

    return {
      dsl: result.calculationDsl?.dsl || '',
      calculationItem,
      readItems,
    }
  })
}

const checkError = (
  result: SalesReportCalculationResult
): boolean | undefined => {
  if (result.inputConsistencyResult === undefined) {
    return undefined
  }
  if (
    result.results.manualInputValue === null &&
    result.results.ocrValue === 0
  ) {
    return false
  }
  return !result.inputConsistencyResult
}

const convertToCells = (result: SalesReportCalculationResult): Cell[] => {
  return [
    {
      key: 'name',
      title: result.calculationItem.name,
    },
    {
      key: 'input',
      title: convertValue(result.results.manualInputValue),
    },
    {
      key: 'ocr',
      title: convertValue(result.results.ocrValue),
    },
    {
      key: 'consistencyResult',
      title: convertInputConsistencyResult(result.inputConsistencyResult),
    },
  ]
}

const convertChildCells = (result: SalesReportOCRFormatResult): Cell[] => {
  return [
    {
      key: 'name',
      title: result.ocrFormat.readItem,
    },
    {
      key: 'input',
      title: convertValue(result.result.manualInputValue),
    },
    {
      key: 'ocr',
      title: convertValue(result.result.ocrValue),
    },
    {
      key: 'consistencyResult',
      title: '-',
    },
  ]
}

const convertChildRows = (
  registerIndex: number,
  rowIndex: number,
  result: SalesReportCalculationResult
): ChildRow[] => {
  return result.ocrFormatResults.map((ocrResult) => {
    return {
      key: ocrResult.ocrFormat.id,
      cells: convertChildCells(ocrResult),
    }
  })
}

const convertToRows = (
  registerIndex: number,
  results: SalesReportCalculationResult[]
): Row[] => {
  const dsls = mapResultsToDSLs(results)
  const textResults = parseCalculationDSLsToText(dsls)
  return results.map((result, rowIndex) => {
    return {
      key: result.calculationItem.id,
      cells: convertToCells(result),
      childRows: convertChildRows(registerIndex, rowIndex, result),
      // NOTE: ['=', 'name', '+', 'name', ...] to '= name + name...'
      tooltipText:
        textResults[rowIndex].text.length === 0
          ? undefined
          : textResults[rowIndex].text.join(' '),
      error: checkError(result),
    }
  })
}

const Presenter: SalesReportsPresenter = {
  convertResponseToTableData(salesReportWithTenants) {
    return salesReportWithTenants.map((salesReportWithTenant) => {
      const {
        tenant,
        tenantDetail,
        registers,
        resentRegisters,
        date,
        reportMessage,
        manageMessage,
        reportStatus,
        isAllManualInputValuesZero,
        extractedConsistencyResult,
      } = salesReportWithTenant

      const hasMultipleRegisters = registers.length > 1

      return registers.map((register) => {
        // NOTE: 何番目のレジか、を画面に表示するための処理
        // 1 番目はそのまま
        // .e.g. tenantName (1)
        const tenantName = hasMultipleRegisters
          ? `${tenant.name} (${register.index + 1})`
          : tenant.name

        return {
          id: register.id,
          name: tenantName,
          code: tenant.code,
          updatedAt: register.updatedAt,
          salesReportDate: dayjs(date).format('YYYY年M月D日'),
          registerIndex: register.index,
          concatOriginal: register.receiptImage?.concatOriginal,
          separatedOriginal: register.receiptImage?.separatedOriginal,
          resized: register.receiptImage?.resized,
          rows: convertToRows(register.index, register.calculationResults),
          tenantDetail,
          reportStatus,
          manageMessage: manageMessage || '',
          reportMessage: reportMessage || '',
          isAllManualInputValuesZero: isAllManualInputValuesZero ?? false,
          extractedConsistencyResult: extractedConsistencyResult ?? false,
          resentRegisters: resentRegisters || [],
        }
      })
    })
  },
  convertFilterValue(value) {
    switch (value) {
      case 'any':
        return ConsistencyMismatch.Any
      case 'input':
        return ConsistencyMismatch.Input
      case 'none':
      default:
        return undefined
    }
  },
  getFilterValuesFromQuery(
    startDate,
    endDate,
    reportStatus,
    aggregatedResult,
    filterForMismatch,
    tenantCodeOrName
  ) {
    const convertedAggregatedResults: ConvertedAggregatedResult[] =
      aggregatedResult.map((item: string) => JSON.parse(item))

    return {
      searchText: tenantCodeOrName || '',
      startDate,
      endDate,
      reportStatus,
      filterForMismatch,
      consistencyEnums: convertToConsistencyEnum(convertedAggregatedResults),
    }
  },
}

export default Presenter
