import React, { useCallback } from 'react'
import { Box, Divider } from '@mui/material'
import { RoleEnum } from '../../../slices/services/api'
import CenteringLoading from '../../molecules/CenteringLoading'
import SmallGuide from '../../molecules/SmallGuide'
import TenantFilter, { TenantFilterProps } from '../../molecules/TenantFilter'
import TenantListWithPagination, {
  TenantListWithPaginationProps,
} from '../../organisms/TenantListWithPagination'
import Button from '../../atoms/Button'
import styles from './styles.module.scss'
import { isOneOfRoles, TenantManagementRoles } from '../../../domain/role'

export type StoreTenantsTabProps = {
  role?: RoleEnum
  isLoading: boolean
  showsUnderline?: boolean
  stickyTop?: number
  onClickAddTenant?: () => void
  onClickEditTenant?: () => void
} & TenantFilterProps &
  Omit<TenantListWithPaginationProps, 'useStoreName'>

const StoreTenantsTabTemplate: React.StyledFC<StoreTenantsTabProps> = ({
  role,
  isLoading,
  currentPage,
  filters,
  rowsPerPage,
  showsUnderline = false,
  stickyTop = 0,
  tenants,
  tenantCount,
  paginationOnClick,
  searchFieldOnChange,
  defaultSearchValue,
  isChecked,
  onChangeCheck,
  onClickAddTenant,
  onClickEditTenant,
}: StoreTenantsTabProps) => {
  const showSwitchByRole = useCallback((): boolean => {
    return isOneOfRoles(TenantManagementRoles, role)
  }, [role])

  const renderTenantList = () => {
    return tenantCount && tenantCount > 0 ? (
      <TenantListWithPagination
        {...{
          currentPage,
          rowsPerPage,
          tenantCount,
          tenants,
          useStoreName: false,
          paginationOnClick,
        }}
      />
    ) : (
      <SmallGuide
        icon="pray"
        className={styles.guide}
        message="該当の結果はありません"
        description="検索キーワードを確認して再度試してください"
      />
    )
  }

  return (
    <Box className={styles.root}>
      <Box className={styles.header} style={{ top: stickyTop }}>
        {showSwitchByRole() && (
          <Box className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              color="secondary"
              size="medium"
              onClick={onClickAddTenant}
              title="テナント新規追加"
            />
            <Button
              className={styles.button}
              color="primary"
              size="medium"
              onClick={onClickEditTenant}
              title="テナント編集"
            />
          </Box>
        )}
        <TenantFilter
          {...{
            filters,
            tenantCount,
            searchFieldOnChange,
            defaultSearchValue,
            isChecked,
            onChangeCheck,
          }}
        />
        <Divider
          className={styles.divider}
          style={{ opacity: showsUnderline ? 1 : 0 }}
        />
      </Box>
      {isLoading ? <CenteringLoading /> : renderTenantList()}
    </Box>
  )
}

export default StoreTenantsTabTemplate
