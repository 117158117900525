import React from 'react'
import { Box, Button, FormControlLabel, Typography } from '@mui/material'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import styles from './styles.module.scss'

import ProgressBar from '../../../../components/atoms/ProgressBar'
import CheckBoxWithFormControl from '../../../../components/atoms/CheckBoxWithFormControl'
import { FormNameEnum } from '../../type'

export type ProgressConfirmBarProps = {
  progress: number
  totalCount: number
  hasExceededSumCell: boolean
  currentCount: number
  disabledConfirmButton?: boolean
  isEnableToFinalizeAndFilterByMisMatchAndSendMessageByRoles: boolean
  onClickSkip: () => void
  onClickConfirm: () => void
  onClickBack: () => void
} & React.StyledProps

const ProgressConfirmBar: React.StyledFC<ProgressConfirmBarProps> = ({
  className,
  progress,
  totalCount,
  hasExceededSumCell,
  currentCount,
  disabledConfirmButton,
  isEnableToFinalizeAndFilterByMisMatchAndSendMessageByRoles,
  onClickSkip,
  onClickConfirm,
  onClickBack,
}: ProgressConfirmBarProps) => {
  const {
    formState: { errors },
  } = useFormContext()
  const isAnyOverTenDigit = Boolean(errors.registers) || hasExceededSumCell

  return (
    <Box className={clsx(className, styles.footer)}>
      <Box className={styles.footerLeft}>
        <Button
          disableElevation
          className={clsx(styles.button, styles.whiteButton)}
          variant="outlined"
          color="inherit"
          size="medium"
          onClick={onClickBack}
        >
          もどる
        </Button>
      </Box>
      <Box className={styles.footerCenter}>
        <Box className={styles.progress}>
          <ProgressBar progress={progress} />
        </Box>
        <Box className={styles.progressText}>
          <Typography variant="body2" component="span" color="textSecondary">
            {currentCount}/{totalCount}
          </Typography>
        </Box>
        <Box className={styles.ngCheck}>
          <FormControlLabel
            label="不一致のみ表示"
            control={
              <CheckBoxWithFormControl
                name={FormNameEnum.filterForMismatch}
                shape="rectangle"
              />
            }
            className={styles.ngCheckText}
            disabled={
              !isEnableToFinalizeAndFilterByMisMatchAndSendMessageByRoles
            }
          />
        </Box>
      </Box>
      <Box className={styles.footerRight}>
        <Button
          className={clsx(styles.button, styles.skipButton, styles.whiteButton)}
          disableElevation
          variant="outlined"
          color="inherit"
          size="medium"
          onClick={onClickSkip}
        >
          スキップ
        </Button>
        <Button
          className={styles.button}
          disableElevation
          variant="contained"
          size="medium"
          onClick={onClickConfirm}
          disabled={
            disabledConfirmButton ||
            !isEnableToFinalizeAndFilterByMisMatchAndSendMessageByRoles ||
            isAnyOverTenDigit
          }
        >
          確定してつぎへ
        </Button>
      </Box>
    </Box>
  )
}

export default ProgressConfirmBar
